.user-list-container {
    padding: 30px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 30px;
  }
  
  .user-profile-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: black;
  }
  
  .user-profile-link h3 {
    padding: 10px 13px;
    background-color: #d3d3d3;
    border-radius: 50%;
  }
  
  .user-profile-link h5 {
    margin: 0px 10px;
  }