.left-sidebar{
  width: 164px;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow ease-in-out 0.1s, transform 0.1s;
  transform: translateZ(0);
  box-sizing: border-box ;
  font-size: 13px; 
}

.side-nav{
  height: auto;
  max-width: 100%;
  position: sticky;
  margin: 50px 0px;
  padding: 20px 0px;
}

.side-nav-div{
  padding: 10px 0px;

}

.side-nav-div div{
  padding-left: 10px;

}

.side-nav-links{
  text-decoration: none;
  color: #3a3a3a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s;
}

.side-nav-links:hover{
  color: black;

}

.active{
  font-weight: bolder;
  color: black;
  background-color: rgb(225, 225, 225);
  border-right: solid 3px #ef8236;

}