.ask-question{
  min-height: 100vh;
  background-color: #f1f2f3;
}

.ask-ques-container{
  margin: auto;
  max-width: 1200px;
}

.ask-ques-container h1{
  padding: 80px 0px 20px 0px;
}

.ask-ques-container form .ask-form-container{
  padding: 20px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 10px 25px rgb(0 0 0 / 5%),
   0 20px 48px rgb(0 0 0 / 5%),
   0 1px 4px rgb(0 0 0 / 10%);
}

.ask-form-container label h4{
  margin-bottom: 0%;
}

.ask-form-container label p{
  margin: 0%;
  font-size: 13px;
  padding: 3px 0px;
}

.ask-form-container label input,
.ask-form-container label textarea{
  padding: 10px;
  border: solid 1px #0000003e;
  font-family: 'Roboto', sans-serif;
  width: calc(100% - 20px);
  resize: vertical;
}

.review-btn{
  margin: 50px 0px;
  padding: 10px;
  background-color: #009dff;
  border: solid 1px #009dff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}
.review-btn:hover{
  background-color: #0086d8;
}